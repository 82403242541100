<template>
  <div>
    <requirement-quality-type-add-new
      :is-add-new-requirement-quality-types-sidebar-active.sync="isAddNewRequirementQualityTypesSidebarActive"
      @refresh-data="() => { refRequirementQualityTypeList.refreshData() }"
    />
    <list
      ref="refRequirementQualityTypeList"
      sort-by="requerimientoCalidadTipoId"
      :refetch-records="fetchRequirementQualityTypes"
      refetch-records-name="requerimientoCalidadTipos"
      :actions="actions"
      key-field="requerimientoCalidadTipoId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteRequirementQualityType"
      :filters.sync="filters"
    />
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import actionsImprovement from '@/services/actionsImprovement.service'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import RequirementQualityTypeAddNew from './RequirementQualityTypeAddNew.vue'

export default {
  components: {
    RequirementQualityTypeAddNew,
    List,
  },
  setup() {
    /* Refs */
    const refRequirementQualityTypeList = ref(null)
    const isAddNewRequirementQualityTypesSidebarActive = ref(false)
    /* Services */
    const { fetchRequirementQualityTypes, fetchRequirementQualityType, deleteRequirementQualityType } = actionsImprovement()
    const tableColumns = [
      {
        label: 'Nombre',
        key: 'nombre',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'write',
        aclResource: 'AccionesMejora',
        routeName: 'apps-requirement-quality-type-edit',
        params: ['requerimientoCalidadTipoId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'AccionesMejora',
        params: ['requerimientoCalidadTipoId'],
        // disabledCondition: item => item.estadoCivilId === 3,
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        label: 'Nuevo requerimiento calidad tipo',
        aclAction: 'create',
        click: () => { isAddNewRequirementQualityTypesSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
    ])
    const filters = ref([])
    return {
      // Ref
      refRequirementQualityTypeList,
      tableColumns,
      isAddNewRequirementQualityTypesSidebarActive,
      tableActions,
      actions,
      filters,
      // API Calls
      fetchRequirementQualityTypes,
      fetchRequirementQualityType,
      deleteRequirementQualityType,
    }
  },
}
</script>
<style lang="scss" scoped>

</style>
